import React, { Component } from 'react'
import { Navbar} from 'react-bootstrap'

class FooterContainer extends Component{

render(){
	let currentDate = new Date();
	let currentYear = currentDate.getFullYear();
           return(
               <div className="site-main>">
             	  <div className="footer-bar">
						<ul className="footer-nav-bar-ul">
							<Navbar>
								<li className="footer-active-copy"> <small className="welcome-home">&copy; {currentYear} <strong>Burak Kocak</strong></small></li>
							</Navbar>
               	         </ul>
				</div>
			 </div>)}
}

export default FooterContainer
