import React from 'react'



const AutobiographyContainer = () =>{
            var imageName = require('../images/family-photo.jpg')
    
                 return(
                     <div>
                     <div className='about-me'>
                     <h4 className='edu-title'>About me</h4>

                            <img src={imageName} className="family-photo" alt=""/>

                            <span className='me-paragraph'><p>I was born and raised in a small town called <a href="https://en.wikipedia.org/wiki/Cihanbeyli">Cihanbeyli</a> located in the province of <a href="https://hometurkey.com/en/destinations/konya">Konya</a>/<a href='https://www.goturkeytourism.com/'>Turkey.</a> </p>
                             <p>
                            My family owned and operated a gas station along with carring on a century-long farming legacy.
                            I have spent most of my childhood around my grandfather who taught pretty much everything about life and honesty.
                            </p>

                            <p>
                            After completing my first year at the college, I decided to move to the United States in the summer of 2002 to live with my father 
                            who lived in Marietta, GA at the time.
                            </p>

                            <p>
                            Over the years, I have worked at many different jobs in the U.S. The jobs varied from dish-washing to waiting tables, delivering 
                            papers/pizza to delivering packages, working on cars to selling parts at a retail store.
                            </p>

                            <p>
                            Along with all the jobs that I worked at, I have also worked on cars on the side. I have completed extensive repairs on 
                            different makes and models. 
                            </p>

                            <p>
                            I have rebuilt over 30 gas engines on cars made by domestic and foreign companies. I have enjoyed every minute I have spent rebuilding engines. I have made to promise myself to learn something new every time I had a chance to work on cars.
                            </p>

                            <p>
                            I had owned and operated used-car-broker company for two years. During these two years, I have gained knowledge on how to create 
                            and operate a business flawlessly while keeping honesty and customer service as priority.
                            I have always put pride on my work no matter where I worked at.
                            </p>

                            <p>
                            Over the years , I have worked hard to support my family financially and mentally. 
                            I am someone who does lots of research before starting to work on a task/project. I believe that planned preperation can help you overcome obstacles. 
                            </p>

                            <p>
                            While working hard and learning new skills over the years, I was fortunate enough to spare time for hobbies. I have played soccer at every chance I got.
                            It was mainly after school when I was in Turkey and it was on the weekends when I had off from work in the US.
                            </p>

                            <p>
                            I am a fan of <a href="https://www.galatasaray.org/en/Homepage">Galatasaray SK</a>.  
                            I try to spend lot of time outside. I enjoy cutting grass, planting trees, flowers and vegetables. 
                            </p>
                            </span>
                            <br></br>

                    </div>
                    
                    <div className="about-me">
                    <h4 className='edu-title'>Summary of My Education</h4>
                    <p className='me-paragraph'>I completed high school in my home town, Cihanbeyli located in province of Konya/Turkey in 1999.
                               Upon graduating from high school, I was accepted at Mining Engineering major at Selcuk University in Konya.
                               After completing first year in Mining Engineering major, my vision had changed.
                               I have always wanted to be engineer however leaning towards electronics and software were something I would be much more interested in.
                               So, I took a year off and prepared intensely for annual college exam in Turkey. I achieved considerably high score that got me accepted at Electrical Engineering and Electronics major at Selcuk University.
                                   After completing the first year, I decided to move to United States to live with my father while I continue to pursuing my college degree.
                                   I studied English as second language at language institute of Georgia Tech in Atlanta, GA.
                                   Upon completing the course, I applied to Georgia Perimeter College, and I got accepted at engineering program.
                                   There were many hardships I had to face in personal life that made me drop out of college twice, but I was not ready to give up.
                                       When the course was half-way completed, I wanted to switch my major to Computer Science.
                                       I had to be more be persistent and graduate once for all.In Spring of 2018, I graduated as an Associate of Computer Science from Georgia State University/Georgia Perimeter College.
                                       Landing a job after graduating from college was not happening early as I expected, then I started to research on how I could improve my skills in order to secure a job in Software.
                                       I wanted to learn different applications other than what I was taught at the college. After a decent amount research, I decided to attend to a coding boot camp.
                                           Then I found Flatiron School along with others. The reason that I wanted pursue my education at Flatiron School was that it's course prep material and technical interviews were very challenging.
                                           They expected a lot from a potential student. I started the program in February of 2019 and I graduate in August of 2019. My experience during Flatiron was extremely challenging yet very rewarding.
                                           I became capable enough to create full-stack web apps in different platforms from what I learned at Flatiron School. I kept learning newer technologies everyday from different sources online. Like many developers state, you never stop learning if you are in tech because it changes in very fast pace.
                                           I have educated myself even more working with experienced developers at Young Consulting.</p>
           </div>
           </div>)

    
}
export default AutobiographyContainer
