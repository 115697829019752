


export default function managePersonal(state = { blogList:[],skillSet:[],
                comments: [],skillSetId: '', likeHolder: null, dislikeHolder: null, size: null                  
            }, action) {

            switch (action.type) {
                     
                     //START_GETTING_BLOGS displays a text while async function is getting blogs from Medium.com
                     case 'START_GETTING_BLOGS':
                        return {message: 'Importing Blog Posts'}
                        
                     //Blogs posts come in from Medium.com
                     case "GET_BLOGS":                      
                        return {...state,blogList:  action.blogs} 
                     
                        //Blog_Detail will return selected blog post
                     case 'BLOG_DETAIL':
                        let theBlog  =[];
                        if(state.blogList){
                         theBlog = state.blogList.items[action.blogIndex];
                        }
                        return   theBlog;

                        //Determines screen size when adjusted. 
                     case "SET_SIZE":                       
                        return {...state,size: action.size}                          

                   default:
                     return state;
                  }

 }