import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react'
import {connect} from 'react-redux';

const ProjectsContainer = () =>{
      let projectsList= [ {projectNo: 2, name:"Property Management",description: "A Sinatra app with basic user authentication and MVC", url: "", frameWork: "Ruby/SinatraApp, #Auth, #FullStack",pic: require("../images/sinatra.png"), sourceCode: "https://github.com/burakkocak884/Sinatra_project"},
                          {projectNo: 3, name:"Garage Management",description: "A Rails app with devise user authentication, MVC, Active Record Validators, Third party user authentication through GitHub, and JavaScript ", url:"",frameWork: "RoR/JavaScript, #Auth, #FullStack", pic: require("../images/screen-top.jpg"), sourceCode: "https://github.com/burakkocak884/top-gear-tech"},
                          {projectNo: 4, name:"Manufacturer Finder", description: "A React app with Redux, data fetch from an external API, Semantic ui", url:"https://manufacturer-finder-2ea571.netlify.app",frameWork: "React/Redux, #API",pic: require("../images/screen-manu.jpg"), sourceCode: "https://github.com/burakkocak884/manufacturer-finder"},
                          {projectNo: 5, name: "Mr Gadget",description: "A React/Redux app with external API calls, interactive navbar user, Semantic ui",  url: 'https://mrgadget-a1b92f.netlify.app', frameWork: "React/Redux, #API", pic: require("../images/mrgadget.png"), sourceCode: "https://github.com/burakkocak884/mr_gadget"},
                          {projectNo: 6, name: "Service Tracker",description: "ASP . NET MVC Application with user authentication and PostGresq Database",  url: 'https://github.com/burakkocak884/mvc-project', frameWork: "Asp.NET MVC PostgreSQL", pic: require("../images/serviceTracker.png"), sourceCode: "https://github.com/burakkocak884/mvc-project"},
                          {projectNo: 7, name: "BeSpoked - Bike Shop Management",description: "ASP . NET MVC Application With Entity FrameWork and PostGresq Database",  url: 'https://github.com/burakkocak884/bespoked', frameWork: "Asp.NET MVC PostgreSQL", pic: require("../images/bespoked.png"), sourceCode: "https://github.com/burakkocak884/bespoked"},
                          {projectNo: 8, name: "Hygiene Systems",description: "React based Front-End Application",frameWork: "React", pic: require("../images/hygienesystem.png"), sourceCode: "", url:"https://main--dancing-cucurucho-22f001.netlify.app/"},
                          {projectNo: 1, name: "World Cup 2018 Russia",description: "CLI Project that scrapes data from different online sources",  url: 'https://www.youtube.com/watch?v=GpOFB9tkwqE&t=419s', frameWork: "CLI Ruby, #DataScrape", pic: require("../images/CLI.png"),sourceCode: "https://github.com/burakkocak884/worldcup"}]
      
      projectsList = projectsList.sort((a, b)=>(a.projectNo < b.projectNo)? 1 :-1)
                       
      return(
              <div className ="projects-card-list" >
                  <Card.Group className="project-cards">
                    {projectsList.map((project, index) =>(
                      <Card className = 'project-card' key={index}>
                        <Card.Content id={index}>
                        <a href={project.url} target='_blank' rel="noopener noreferrer" id={index}><Image className='project-card-image' src={project.pic} id={index}/></a>
                          <div className='project-card-content' id={index}>
                            <Card.Header id={index}>
                              <p>{project.name}<span className = "source-code-link" id={index}><strong><a href={project.sourceCode} target='_blank' rel="noopener noreferrer"><Icon name = "code"></Icon></a></strong></span></p>
                            </Card.Header>
                            <Card.Description className = "project-card-description" id={index}>
                              <strong>#{project.frameWork}</strong>
                            </Card.Description>
                          </div>
                          </Card.Content>
                        </Card>
                    ))}
                  </Card.Group>
              </div>
      )
}
const mapStateToProps = state => {
  return{
          size: state.size
      }}

export default connect(mapStateToProps,null)(ProjectsContainer)
