import React from 'react'

const NavbarHome = (props) => {

			var imageName = require('../images/profile9.png')
				
			return(
					<div>
						<div className="personal-section">
							<div className="personal-photo">
								<img src={imageName} className="profile-picture" alt=""/>									
							</div>
							<div className="personal-title">
								<span className='name'>BURAK KOCAK</span>
								<br></br>
								<span className="titles">Full-Stack Developer</span>
								<br></br>
								<span className="titles">Associate of Computer Science</span>
								<br></br>
								<span className="titles">Azure .Net MVC</span>
								<br></br>
								<span className="titles">TypeScript SQL React Ruby</span>
							</div>
						</div>
					</div>
				)
				
}


export default  NavbarHome
