import React, {Component} from 'react';
import NavigationBar from './components/NavigationBar'
import FooterContainer from './containers/FooterContainer'
import './App.css';
import NavbarHome from './components/NavbarHome';
import {connect} from 'react-redux';


    class App extends Component {
      constructor(props) {
        super(props)
    
        this.state = {
            width: 1000,
            size: "desktop",
          }
        }
       
        componentDidMount() {
          window.addEventListener('resize', this.handleWindowSizeChange);
        }
    
        componentWillUnmount() {
          window.removeEventListener('resize', this.handleWindowSizeChange);
        }
    
        handleWindowSizeChange = () => {
          
          this.setState({ width: window.innerWidth });    
          
          let width = this.state.width
          if(width <= 600){
            this.setState({size:  "mobile"})
          } else if (width > 600 && width < 900){
            this.setState({size:  "ipad"})
          } else if (width >= 900 && width < 1400){
            this.setState({size:  "desktop"})
          } else if (width >= 1400 && width < 1700){
            this.setState({size:  "largeDesktop"})
          }else {
            this.setState({size: "wideScreen"})
          } 
         
          this.setState({ size: this.state.size })
    
          this.props.setSize(this.state.size)
          
    
        }





    render(){
   
        return (
          <div className="site">
                  <div className= "left-area">
                    <NavbarHome />
                  </div>
                  <div className = 'main-content'>
                    <NavigationBar />
                  </div>
                  
                  <FooterContainer />
            </div>
        )
      }
      }
     
      const mapStateToProps = state => { 
        return { size: state.size } 
      }
      const mapDispathToProps = dispatch => ({
        setSize: size => dispatch({type: 'SET_SIZE', size})
      })
      export default connect(mapStateToProps, mapDispathToProps)(App)