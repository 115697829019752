import React from 'react'
import {connect} from 'react-redux';
import './Home.css'

const Home = (props) =>{
  
                let quotes = [`“A language that doesn't affect the way you think about programming is not worth knowing.”
                ― Alan J. Perlis`, `“Walking on water and developing software from a specification are easy if both are frozen.”
                ― Edward V. Berard`,`“The most disastrous thing that you can ever learn is your first programming language.”
                ― Alan Kay`,`“The most important property of a program is whether it accomplishes the intention of its user.”
                ― C.A.R. Hoare`, `“Object-oriented programming offers a sustainable way to write spaghetti code. It lets you accrete programs as a series of patches.”
                ― Paul Graham, Hackers & Painters: Big Ideas from the Computer Age`,`“Some of the best programming is done on paper, really. Putting it into the computer is just a minor detail.”
                ― Max Kanat-Alexander, Code Simplicity: The Fundamentals of Software`, `“Programmers are not to be measured by their ingenuity and their logic but by the completeness of their case analysis.”
                ― Alan J. Perlis`, `It is not enough to do your best: you must KNOW what to do, and THEN do your best.- W.Edwards Deming`,
                `"It’s hard enough to find an error in your code when you’re looking for it; its even harder when you’ve ASSUMED your code is ERROR-FREE."- Steve McConnell`, 
            `“Programs must be written for people to read, and only incidentally for machines to execute.”
            ― Harold Abelson, Structure and Interpretation of Computer Programs`,
            `“Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live”
            ― John Woods`, `“Programming today is a race between software engineers striving to build bigger and better idiot-proof programs, and the Universe trying to produce bigger and better idiots. So far, the Universe is winning.”
            ― Rick Cook, The Wizardry Compiled`, `“Any fool can write code that a computer can understand. Good programmers write code that humans can understand.”
            ― Martin Fowler`,`“I'm not a great programmer; I'm just a good programmer with great habits.”
            ― Kent Beck`,`“Give a man a program, frustrate him for a day.
            Teach a man to program, frustrate him for a lifetime.”
            ― Muhammad Waseem`,`“Perl – The only language that looks the same before and after RSA encryption.”
            ― Keith Bostic`,`“When they first built the University of California at Irvine they just put the buildings in. They did not put any sidewalks, they just planted grass. The next year, they came back and put the sidewalks where the trails were in the grass. Perl is just that kind of language. It is not designed from first principles. Perl is those sidewalks in the grass.”
            ― Larry Wall`,`“A computer is like a violin. You can imagine a novice trying ﬁrst a phonograph and then a violin. The latter, he says, sounds terrible. That is the argument we have heard from our humanists and most of our computer scientists. Computer programs are good, they say, for particular purposes, but they aren’t ﬂexible. Neither is a violin, or a typewriter, until you learn how to use it.”
            ― Marvin Minsky`, `“Progress is possible only if we train ourselves to think about programs without thinking of them as pieces of executable code. ”
            ― Edsger W. Dijkstra`,`“The issue of finding the best possible answer or achieving maximum efficiency usually arises in industry only after serious performance or legal troubles.”
            ― Steven S. Skiena, The Algorithm Design Manual`,`“I'm a programmer. I like programming. And the best way I've found to have a positive impact on code is to write it.”
            ― Robert C. Martin, Clean Architecture`,`“User interface is the process of shifting from chaotic complexity to elegant simplicity.”
            ― Akshat Paul, React Native for iOS Development`,`“The personal computer isn't "personal" because it's small and portable and yours to own. It's "personal" because you pour yourself into it - your thoughts, your programming.”
            ― Audrey Watters, The Monsters of Education Technology`]
   
                 let theQuote = quotes[Math.floor(Math.random()*quotes.length)]
                    
                         return(
                                <div className='index-message'>
                                    <p className="quote">{theQuote}</p>
                                </div>
                             )}

const mapStateToProps = state => { 
    return { size: state.size } 
  }
export default connect(mapStateToProps)(Home)