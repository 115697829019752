
	    
	    // getBlogs is a async funtion that sends multiple dispatch
		export const getBlogs = () => {	
			return (dispatch) => {
					dispatch({ type: 'START_GETTING_BLOGS' });
					return fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@burakkocak884`)
					.then(response => response.json())
					.then(blogs =>  {
					dispatch({ type: 'GET_BLOGS', blogs})
					});
			}
		}
	

		export const blogDetail = blogIndex =>{
			return{
				type: 'BLOG_DETAIL',
				blogIndex
			}
		}

		export const setSize = size =>{		
		  	return{
				  type: 'SET_SIZE',
				  size
			}
		}
