import React, {Component} from 'react'
import {BrowserRouter as Router,Route, Switch,Link} from 'react-router-dom'
import { Navbar} from 'react-bootstrap'
import AutobiographyContainer from '../containers/AutobiographyContainer'
import ProjectsContainer from '../containers/ProjectsContainer'
import Blog from '../components/Blog'
import {getBlogs} from '../actions/Personal'
import {connect} from  'react-redux'
import Home from './Home'
import ContactContainer from '../containers/ContactContainer'



  class NavigationBar extends Component{
	 

	render(){
				return (
					<div className = "nav-bar-wrapper">
						<Router>
							<>
								<b>
									<ul className="nav-bar-ul">
										<Navbar>
												<li><Link to = "/home">Home</Link></li>
												<li><Link to = "/projects">Projects</Link></li>
												<li><Link to = "/blog" >Blog</Link></li>
												<li><Link to = "/about">About</Link></li>
												<li><Link to = "/contact" >Contact</Link></li>
										</Navbar>
									</ul>
								</b>

								<Switch>
										<Route exact path='/' render={() => (<Home />)}></Route>
										<Route path='/home' render={() => (<Home />)}></Route>
										<Route path='/projects' render={() => (<ProjectsContainer />)}></Route>
										<Route path='/blog'   render={() => (<Blog  onClick={this.props.getBlogs} />)}></Route>
										<Route path='/about' render={() => (<AutobiographyContainer />)}></Route>
										<Route path='/contact'   render={() => (<ContactContainer />)}></Route>
								</Switch>
							</>
						</Router>
					</div>
					)
			}
		
		
}
  const mapStateToProps=(state)=>{
	  return{
		  size: state.size
	  }

  }

export default connect(mapStateToProps, {getBlogs})(NavigationBar)