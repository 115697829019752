import React,{Component} from 'react'
import {connect} from 'react-redux';
import {getBlogs, blogDetail} from '../actions/Personal'

import { Card, Image , Icon} from 'semantic-ui-react'


  class Blog extends Component{

      componentDidMount(){this.props.getBlogs()}
      
      render(){
        
       let unfetchedBlogs = [
                              {title: "One Student's Journey from Paper and Pizza Delivery to Software Engineer",
                              pubDate: "2019-12-26",
                              author: "Flatiron School",
                              categories:["!Slackers","Success","Commitment","Team Work","Trust","Innovation","Support"],
                              link:"https://flatironschool.com/blog/one-students-journey-from-paper-and-pizza-delivery-to-software-engineer",
                              thumbnail: require('../images/flatironpicture.PNG')},
                              {
                              title: "Experience of Scraping Data in CLI vs Fetching Data in React/Redux",
                              pubDate: "2019-09-20",
                              author: "Burak Kocak",
                              categories:["React","Rails API","Redux Thunk","JavaScript"],
                              link:"https://medium.com/@burakkocak884/experience-of-scraping-data-in-cli-vs-fetching-data-in-react-redux-79c8fcc7d692",
                              thumbnail: 'https://miro.medium.com/max/1200/1*KvP9C-QfCG0Zh5vmDTSNiw.png'},
                              {title: "autoTech.toCODE()",
                              pubDate: "2019-09-27",
                              author: "Burak Kocak",
                              categories:["Technology","Auto Tech","JavaScript","ReactJS", "Coding"],
                              link:"https://medium.com/@burakkocak884/auto-technician-to-coding-7e04762f6ed6",
                              thumbnail: 'https://miro.medium.com/max/1200/1*cmTCDbEMo4vCSQZnhx-84w.jpeg'}]

        let rowCount = 1;

        if(this.props.message && this.props.myBlogs){

          const {items} = this.props.myBlogs;
          //medium.com will limit list of publishings to 10 on an api call.
          //older publishes have been hard coded and stored above in unfetchedBlogs.
          //We will merge what we have in unfetchedBlogs to what is coming from medium api.
          for(let i = 0; i < unfetchedBlogs.length; i++){
            if(!items.includes(unfetchedBlogs[i])){
              if(unfetchedBlogs[i].author === "Flatiron School")
              {
                items.unshift(unfetchedBlogs[i]);
              }
              else {
                items.push(unfetchedBlogs[i]);
              }
            }
          }
          return(
            <div className="blog-container">
              <Card.Group className='blog-cards-container' itemsPerRow={rowCount}>
                {items.map((blog,index)=>(
                <Card className = "individual-card" key={index} >
                  <Card.Content>
                  <a href={blog.link} target='_blank' rel="noopener noreferrer" className = ''>
                      <Image size='medium' src={blog.thumbnail} className='blog-card-image'/>
                      </a>
                      <br />
                      <br />
                      <Card.Header className = 'blog-car-header'>{blog.title}</Card.Header>
                      <Card.Description> <strong>#{blog.categories.join(' #')}</strong> </Card.Description>
                      <br/>
                      <Card.Description className ='card-publish-date'>{blog.pubDate.substring(0,10)}</Card.Description>
                  </Card.Content>
                </Card>
              ))}
              </Card.Group>
            </div>)
        } 
        else
        {
          return(
                  <div> 
                    <Icon loading name='certificate' />
                    <h1 className="loading-message">{this.props.message != null? this.props.message : 'It is taking longer than expected...'}</h1>
                    <Icon loading name='certificate' />
                  </div>
                )
        }
    }
}

const mapStateToProps = state => {
  return{
          myBlogs: state.blogList,
          message: state.message,
          size: state.size
      }}

export default  connect(mapStateToProps,{getBlogs,blogDetail})(Blog) 
